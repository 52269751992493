
import { Form as Validation } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import { copyToClipboard } from '@/services/utils'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import PageAuth from '@/components/layout/PageAuth.vue'

export default defineComponent({
  components: {
    Validation,
    TmButton,
    TmFormLine,
    TmTooltip,
    PageAuth,
  },
  setup() {
    const users = ref([])
    const usersRole = ref('Administrator')
    const usersRoleOptions = ref([
      'Administrator',
      'Agent',
      'Supervisor',
      'Accountant',
    ])
    const copied = ref(false)
    const copyFunc = (text: string) => {
      copied.value = true
      copyToClipboard(text)
    }
    return {
      users,
      usersRole,
      usersRoleOptions,
      copied,
      copyFunc,
    }
  },
})
